import React from "react"
import cx from "classnames"

import Layout from "../components/layout"
import { IMG } from "../const"
import * as styles from "../assets/styles/minutes_depot.module.scss"
import "../assets/styles/commons/lists/_common__list__mdashed.scss"
import FeedbackSection from "../components/feedback-section"

const MinutesDepot = () => {
  return (
    <Layout pageTitle="Minutes Depot application">
      {() => {
        return (
          <>
            <main className={styles.minutes_depotBlock}>
              <div className={"container-fluid"}>
                <div className={"row"}>
                  <div
                    className={cx(
                      styles.mainImageMinutesDepot,
                      "col-xs-12 col-sm-12 col-md-12 col-lg-12"
                    )}
                  >
                    <div className={"row"}>
                      <div className="col-xs-5 offset-xs-2 col-sm-4 offset-sm-2 col-md-4 offset-md-2 col-lg-3 offset-lg-2">
                        <div className="">
                          <h1 className={styles.minutesDepotText}>
                            Minutes Depot
                          </h1>
                          <p className={styles.underTitleTextMinutesDepot}>
                            Online Bookkeeping solution for Corporate market
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.titleLine}>
                <div className="common__article__section">
                  <h2
                    className={cx(
                      styles.titleTextMinutesDepot,
                      styles.titleLineAlign
                    )}
                  >
                    Services &amp; Technologies
                  </h2>
                </div>
              </div>
              <div className={"container-fluid"}>
                <div className={"row"}>
                  <div
                    className={cx(
                      styles.servicesBlock,
                      styles.blockColor1MinutesDepot,
                      "col-xs-12 col-sm-4 col-md-4 col-lg-4"
                    )}
                  >
                    <p className={styles.blockTitles}>
                      project <br className={styles.brline} />
                      management
                    </p>
                    <p className={styles.blockText}>Scrum Methodology</p>
                  </div>
                  <div
                    className={cx(
                      styles.servicesBlock,
                      styles.blockColor2MinutesDepot,
                      "col-xs-12 col-sm-4 col-md-4 col-lg-4"
                    )}
                  >
                    <p className={styles.blockTitles}>
                      front-end <br className={styles.brline} />
                      development
                    </p>
                    <p className={styles.blockText}>JavaScript</p>
                    <p className={styles.blockText}>HTML, CSS</p>
                  </div>
                  <div
                    className={cx(
                      styles.servicesBlock,
                      styles.blockColor3MinutesDepot,
                      "col-xs-12 col-sm-4 col-md-4 col-lg-4"
                    )}
                  >
                    <p className={styles.blockTitles}>
                      back-end <br className={styles.brline} />
                      development
                    </p>
                    <p className={styles.blockText}>Ruby on Rails</p>
                  </div>
                </div>
              </div>
              <div className={styles.termsLine}>
                <div className="common__article__section__zeroIndentTop common__article__section__zeroIndentBottom">
                  <p>Terms: Dec 2015–in progress</p>
                  <p>Customer’s country: Canada</p>
                </div>
              </div>
              <section className="common__article__section upTo2colsImgTxt">
                <div>
                  <h2
                    className={cx(
                      styles.titleTextMinutesDepot,
                      styles.titleLineAlign
                    )}
                  >
                    What is minutes depot Application?
                  </h2>
                  <p>
                    Minutes is an Online Bookkeeping solution for the Corporate
                    market. The Solution helps to move all internal
                    documentation online in a PDF format with the following
                    benefits:
                  </p>
                  <ul className="common__list__mdashed">
                    <li>
                      Respect the highest security standards in the industry
                    </li>
                    <li>Unlimited number of collaborators on a minute book</li>
                    <li>
                      Manage collaborator permission for reading or writing
                    </li>
                    <li>Smart Transaction Management</li>
                    <li>A complete history of each operation</li>
                    <li>Flexible Billing Module</li>
                    <li>Multiplatform Access</li>
                    <li>Daily Backup</li>
                  </ul>
                </div>
                <div className={styles.iphone}>
                  <img
                    src={`${IMG.INDEX}/img/minutesDepot/iPhone6_mockup_truacur.png`}
                    alt="Online Bookkeeping solution for corporate market"
                    loading="lazy"
                    width="385"
                    height="454"
                  />
                </div>
              </section>
              <div className={styles.greyBlockMinutes}>
                <section className="common__article__section upTo2cols">
                  <h2
                    className={cx(
                      styles.titleTextMinutesDepot,
                      styles.titleLineAlign
                    )}
                  >
                    Minutes Depot Features
                  </h2>
                  <div>
                    <h3 className={styles.featuresTitle}>
                      Manage Shareholders, Officers and Directors
                    </h3>
                    <p>
                      Lists can be modified in the Minutes Depot interface and
                      can be linked to related documents, such as an officer’s
                      acceptance of a role or a resignation, if applicable.
                    </p>
                    <h4 className={styles.featuresTitle}>
                      Share Management Made Easy
                    </h4>
                    <p>
                      The share management interface makes the issuance,
                      transfer, or buyback of shares easy. A user's share
                      certificates are kept up to date and available at all
                      times.
                    </p>
                    <h4 className={styles.featuresTitle}>
                      Our Document Generator Wizard
                    </h4>
                    <p>
                      After a user answers a few questions and the Minutes Depot
                      Document Generator Wizard will take care of filling out
                      all the sections and documents of the minute book.
                      However, users can skip the document generator if they
                      prefer to use their templates.
                    </p>
                    <h4 className={styles.featuresTitle}>
                      Essential Sharing Tool
                    </h4>
                    <p>
                      Minutes Depot is an easy way to share minute books with
                      professionals, associates, partners and investors. Sharing
                      is done in seconds by entering the person’s name and email
                      address and an invitation is sent out. Users can control
                      access by specifying an expiration date or by limiting
                      access to documents in read-only mode. If necessary, a
                      user can require a trusted user/book administrator to
                      approve documents before they can be added to the
                      corporate record.
                    </p>
                    <h4 className={styles.featuresTitle}>
                      Practical Reminders and Notifications
                    </h4>
                    <p>
                      With reminders, users will always attend to all critical
                      dates, such as completing an annual filing. In addition,
                      Minutes Depot sends users email notifications when one of
                      their associates modifies the minute book.
                    </p>
                  </div>
                  <div>
                    <h4 className={styles.featuresTitle}>Data is Secure</h4>
                    <p>
                      Because of the type of service offered, the management
                      team at Minutes Depot is very aware of the importance of
                      the confidentiality, integrity, and availability of the
                      information in a minute book. By working in partnership
                      with globally-recognized leaders, Minutes Depot is able to
                      maintain the highest security standards in the industry.
                    </p>
                    <h4 className={styles.featuresTitle}>History</h4>
                    <p>
                      With Minutes Depot’s permanent detailed records, the
                      history section of a minute book lets a user see any
                      modifications, who made them, and when they were made.
                    </p>
                    <h4 className={styles.featuresTitle}>
                      Electronic Signature
                    </h4>
                    <p>
                      With Minutes Depot, there is no need to bring in managers,
                      partners, or associates to sign documents. A user can
                      place a copy on the platform and request a signature from
                      interested party. They will each receive 2 unique key
                      codes, one by email and the other by SMS. The combination
                      of these two key codes with their login will verify the
                      person’s identity.
                    </p>
                    <h4 className={styles.featuresTitle}>
                      Paperless Board of Directors
                    </h4>
                    <p>
                      It simplifies the management of meetings. Minutes Depot’s
                      Paperless Board of Directors can manage the complete
                      cycle: meeting invitations, agenda creation, drafting of
                      minutes of proceedings, approvals, and signatures. The
                      platform strictly controls the accuracy of the information
                      present throughout the cycle.
                    </p>
                    <h4 className={styles.featuresTitle}>
                      An Intuitive Search Tool
                    </h4>
                    <p>
                      The search tool lets users find any document in their
                      minute book in seconds. Whether they are searching by
                      description, date, or content, it’s easy to find any
                      document generated by the platform.
                    </p>
                  </div>
                </section>
              </div>
              <div className={"container-fluid"}>
                <div
                  className={cx(styles.block, styles.greyBlockMinutes, "row")}
                >
                  <div className="col-md-10 offset-md-1">
                    <div className={"row"}>
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <p
                          className={cx(
                            styles.titleTextMinutesDepot,
                            styles.titleLineAlign,
                            styles.mB30
                          )}
                        ></p>
                      </div>
                      <div
                        className={cx(
                          styles.whatIsMinutesDepot,
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6"
                        )}
                      ></div>
                      <div
                        className={cx(
                          styles.whatIsMinutesDepot,
                          styles.rightColumnPadding,
                          "col-xs-12 col-sm-12 col-md-6 col-lg-6"
                        )}
                      ></div>
                    </div>
                  </div>
                </div>
                <div
                  className={cx(styles.blockMinutes, styles.pB0Minutes, "row")}
                >
                  <div
                    className={cx(
                      styles.macbookLeftMinutesDepot,
                      "col-xs-12 col-sm-12 col-md-5 col-lg-5"
                    )}
                  ></div>
                  <div
                    className={cx(
                      styles.macbookCenterMinutesDepot,
                      "col-xs-12 col-sm-12"
                    )}
                  >
                    {" "}
                  </div>
                  <div
                    className={cx(
                      styles.whatIsMinutesDepot,
                      "col-xs-12 col-sm-12 col-md-7 col-lg-7"
                    )}
                  >
                    <p
                      className={cx(
                        styles.titleTextMinutesDepot,
                        styles.titleLineAlign
                      )}
                    >
                      Developer’s insight
                    </p>
                    <div>
                      <p className={cx(styles.textMargin, styles.first)}>
                        “It was an exciting experience working with PDF files.
                        In this application, we can not only to load the PDF
                        files but also to modify them. The team was tasked with
                        choosing the most convenient library for processing
                        PDFs. We have implemented a possibility to edit the file
                        before downloading using WYSIWYG-editor. Also, we have
                        implemented a Wizard, which allows the user to create
                        almost all the basic components in one module in a few
                        steps. Also, the Wizard has created two opposite issues
                        for the developers. On the one hand, it had to be as
                        simple as possible for the end users and also consist of
                        a few steps. On the other hand, it had to contain a
                        large amount of functionality in one small module. That
                        was not an easy task.”
                      </p>
                      <p
                        className={styles.textMargin}
                        style={{ marginBottom: "50px" }}
                      >
                        <b>Olena Ohranchuk, Back-end developer</b>
                      </p>
                      <p className={styles.textMargin}>
                        “While working on the Minutes Depot project, our team
                        faced a lot of interesting and challenging tasks. For
                        me, creating dynamic dashboards for users and
                        administrators was a valuable and exciting experience.
                        The user's dashboard dynamically collects all necessary
                        data from a database. It provides a user with real-time
                        information about their smart digital book and all the
                        required actions. Administrator's dashboard allows a
                        customer to have a visual representation of operational
                        information about sales and gain, which identifies
                        trends, measures efficiencies and makes more informed
                        decisions. While implementing the dashboard we used JS
                        libraries to build graphs and info-tables on the fly,
                        depending on a time period and other filters.”
                      </p>
                      <p>
                        <b>Taras Haiduchyk, Back-end developer</b>
                      </p>
                    </div>
                  </div>
                </div>
                <div className={"row"}>
                  <div
                    className={cx(
                      styles.commentBlockMinutesDepot,
                      "col-xs-12 col-sm-12 col-md-12 col-lg-12"
                    )}
                  >
                    {/* <div className={styles.userAvatarMinutesDepot}></div> */}
                    <picture>
                      <source
                        srcSet={`
                        ${IMG.TESTIMONIALS}/Louis-Bedard.avif,
                        ${IMG.TESTIMONIALS}/Louis-Bedard@2x.avif 2x,
                        ${IMG.TESTIMONIALS}/Louis-Bedard@3x.avif 3x`}
                        type="image/avif"
                      />
                      <source
                        srcSet={`
                        ${IMG.TESTIMONIALS}/Louis-Bedard.webp,
                        ${IMG.TESTIMONIALS}/Louis-Bedard@2x.webp 2x,
                        ${IMG.TESTIMONIALS}/Louis-Bedard@3x.webp 3x`}
                        type="image/webp"
                      />
                      <source
                        srcSet={`
                        ${IMG.TESTIMONIALS}/Louis-Bedard.jpg,
                        ${IMG.TESTIMONIALS}/Louis-Bedard@2x.jpg 2x,
                        ${IMG.TESTIMONIALS}/Louis-Bedard@3x.jpg 3x`}
                      />
                      <img
                        src={`${IMG.TESTIMONIALS}/Louis-Bedard.jpg`}
                        alt="Louis Bedard"
                        title="Louis Bedard"
                        width="80"
                        height="80"
                        loading="lazy"
                        style={{ borderRadius: "100%" }}
                      />
                    </picture>
                    <p className={styles.commentTitle}>EXCELLENT WORK!</p>
                    <p className={styles.commentText}>
                      While working on the project, all team cared about our
                      project and needs. We recommend them without a doubt to
                      anyone looking for high-quality and professional
                      programming for a reasonable price.
                    </p>
                    <p className={styles.commentName}>Louis Bedard</p>
                    <p className={styles.commentPosition}>
                      Co-Founder at Minutes Depot
                    </p>
                    {/* <div className="circles"> <img src="img/minutesDepot/Ellipse-2.png" alt="" className="circle first"> <img src="img/minutesDepot/Ellipse-2-copy.png" alt="" className="circle"> <img src="img/minutesDepot/Ellipse-2-copy.png" alt="" className="circle"> <img src="img/minutesDepot/Ellipse-2-copy.png" alt="" className="circle"> <img src="img/minutesDepot/Ellipse-2-copy.png" alt="" className="circle"> </div> */}
                  </div>
                </div>
              </div>
            </main>
            <FeedbackSection number={0} />
          </>
        )
      }}
    </Layout>
  )
}
export default MinutesDepot
