// extracted by mini-css-extract-plugin
export var at1024__pr50p = "minutes_depot-module--at1024__pr50p--3d888";
export var at640__pr50p = "minutes_depot-module--at640__pr50p--0f81b";
export var at768__w50p = "minutes_depot-module--at768__w50p--064bb";
export var bg__nobleGray = "minutes_depot-module--bg__nobleGray--dc652";
export var billetShadow = "minutes_depot-module--billet-shadow--36527";
export var block = "minutes_depot-module--block--1fd62";
export var blockColor1MinutesDepot = "minutes_depot-module--blockColor1MinutesDepot--4e09c";
export var blockColor2MinutesDepot = "minutes_depot-module--blockColor2MinutesDepot--84345";
export var blockColor3MinutesDepot = "minutes_depot-module--blockColor3MinutesDepot--49ae3";
export var blockMinutes = "minutes_depot-module--blockMinutes--6c4dc";
export var blockText = "minutes_depot-module--blockText--6ed5a";
export var blockTitles = "minutes_depot-module--blockTitles--38ac9";
export var brline = "minutes_depot-module--brline--79aa4";
export var btn = "minutes_depot-module--btn--380d4";
export var c = "minutes_depot-module--c--a9ee5";
export var cc18sf = "minutes_depot-module--cc-18sf--0f23f";
export var cc199t = "minutes_depot-module--cc-199t--98c07";
export var cc1ada = "minutes_depot-module--cc-1ada--6248c";
export var cc1aev = "minutes_depot-module--cc-1aev--d54c8";
export var cc1lut = "minutes_depot-module--cc-1lut--bd692";
export var cc1xry = "minutes_depot-module--cc-1xry--7b362";
export var cc2hzo = "minutes_depot-module--cc-2hzo--df2bd";
export var cc2n8m = "minutes_depot-module--cc-2n8m--f60c4";
export var cc4xbu = "minutes_depot-module--cc-4xbu--a8076";
export var ccCaj5 = "minutes_depot-module--cc-caj5--3cea7";
export var ccEw5j = "minutes_depot-module--cc-ew5j--25211";
export var ccG73w = "minutes_depot-module--cc-g73w--fb451";
export var ccKgeu = "minutes_depot-module--cc-kgeu--df11f";
export var ccKv6t = "minutes_depot-module--cc-kv6t--c1999";
export var ccPjpe = "minutes_depot-module--cc-pjpe--25211";
export var ccUnoo = "minutes_depot-module--cc-unoo--5e374";
export var childAs__seeMore = "minutes_depot-module--childAs__seeMore--1eb3a";
export var circle = "minutes_depot-module--circle--ccedd";
export var circles = "minutes_depot-module--circles--de47f";
export var clearAll = "minutes_depot-module--clearAll--9870f";
export var colorScheme__background__grayPale = "minutes_depot-module--colorScheme__background__gray-pale--a9a4e";
export var colorScheme__buttonGold = "minutes_depot-module--colorScheme__buttonGold--23d29";
export var colorScheme__buttonGold__darkBackground = "minutes_depot-module--colorScheme__buttonGold__darkBackground--760bc";
export var colorScheme__linkGold = "minutes_depot-module--colorScheme__linkGold--a5e2a";
export var colorScheme__whiteButton = "minutes_depot-module--colorScheme__whiteButton--e0123";
export var coloredBackground__dark = "minutes_depot-module--coloredBackground__dark--2e45e";
export var coloredBackground__light = "minutes_depot-module--coloredBackground__light--129f5";
export var commentBlockMinutesDepot = "minutes_depot-module--commentBlockMinutesDepot--1602c";
export var commentName = "minutes_depot-module--commentName--48ca9";
export var commentPosition = "minutes_depot-module--commentPosition--c2d8c";
export var commentText = "minutes_depot-module--commentText--6a0e0";
export var commentTitle = "minutes_depot-module--commentTitle--0b300";
export var common__article__section = "minutes_depot-module--common__article__section--62a73";
export var common__article__section__doubleIndentBottom = "minutes_depot-module--common__article__section__doubleIndentBottom--9d84f";
export var common__article__section__zeroIndentBottom = "minutes_depot-module--common__article__section__zeroIndentBottom--15227";
export var common__article__section__zeroIndentTop = "minutes_depot-module--common__article__section__zeroIndentTop--98a5a";
export var common__chips = "minutes_depot-module--common__chips--9a700";
export var common__headline = "minutes_depot-module--common__headline--c804b";
export var common__headline__beveledBottom = "minutes_depot-module--common__headline__beveledBottom--17f99";
export var common__headline__bgLoop = "minutes_depot-module--common__headline__bgLoop--0fa19";
export var common__leadQuote = "minutes_depot-module--common__leadQuote--c95f8";
export var common__projectsTeaser = "minutes_depot-module--common__projects-teaser--0d41b";
export var common__scrollableTarget = "minutes_depot-module--common__scrollableTarget--d2f7a";
export var common__teaser = "minutes_depot-module--common__teaser--ae5b3";
export var common__teaser__button = "minutes_depot-module--common__teaser__button--e702f";
export var common__textOutline = "minutes_depot-module--common__textOutline--cb35a";
export var contentMiddle = "minutes_depot-module--contentMiddle--c6c27";
export var crispChatbox = "minutes_depot-module--crisp-chatbox--3fdca";
export var crispClient = "minutes_depot-module--crisp-client--8ae32";
export var darkBackground = "minutes_depot-module--darkBackground--3041a";
export var darkTxt = "minutes_depot-module--darkTxt--fe316";
export var fatButton = "minutes_depot-module--fatButton--64a3c";
export var featuresTitle = "minutes_depot-module--featuresTitle--950f2";
export var first = "minutes_depot-module--first--78bc2";
export var floatRight = "minutes_depot-module--float-right--1a378";
export var fs1o5 = "minutes_depot-module--fs1o5--e3512";
export var fullWidth = "minutes_depot-module--fullWidth--650d1";
export var goldBackground = "minutes_depot-module--goldBackground--2de69";
export var goldButton = "minutes_depot-module--goldButton--fee6b";
export var goldButton__casePorter = "minutes_depot-module--goldButton__casePorter--9312e";
export var goldButton__centered = "minutes_depot-module--goldButton__centered--85406";
export var goldButton__w14 = "minutes_depot-module--goldButton__w14--69bb0";
export var goldFatLink = "minutes_depot-module--goldFatLink--50d89";
export var greyBlockMinutes = "minutes_depot-module--greyBlockMinutes--cb528";
export var icomoon = "minutes_depot-module--icomoon--7fcef";
export var iphone = "minutes_depot-module--iphone--1388a";
export var l = "minutes_depot-module--l--20d21";
export var last = "minutes_depot-module--last--3ad7b";
export var lineBreak = "minutes_depot-module--lineBreak--a4a3e";
export var listItem = "minutes_depot-module--listItem--f67be";
export var lowercase = "minutes_depot-module--lowercase--dde75";
export var mB30 = "minutes_depot-module--m-b-30--b3f7d";
export var macbookCenterMinutesDepot = "minutes_depot-module--macbookCenterMinutesDepot--1f03e";
export var macbookLeftMinutesDepot = "minutes_depot-module--macbookLeftMinutesDepot--c18bd";
export var mainImageMinutesDepot = "minutes_depot-module--mainImageMinutesDepot--08ba4";
export var maxw20 = "minutes_depot-module--maxw20--21de2";
export var mb2 = "minutes_depot-module--mb2--70bad";
export var minutesDepotText = "minutes_depot-module--minutesDepotText--92734";
export var minutes_depotBlock = "minutes_depot-module--minutes_depotBlock--bd51c";
export var mt2 = "minutes_depot-module--mt2--c4e76";
export var nobr = "minutes_depot-module--nobr--b9818";
export var p0 = "minutes_depot-module--p0--1bbd6";
export var pB0 = "minutes_depot-module--p-b-0--3f040";
export var pB0Minutes = "minutes_depot-module--p-b-0Minutes--88953";
export var pL0 = "minutes_depot-module--p-l-0--b8647";
export var pR0 = "minutes_depot-module--p-r-0--54b86";
export var primaryButton = "minutes_depot-module--primaryButton--0d580";
export var pt2 = "minutes_depot-module--pt2--2cd1f";
export var r = "minutes_depot-module--r--354bc";
export var reverse = "minutes_depot-module--reverse--b4240";
export var rightColumnPadding = "minutes_depot-module--rightColumnPadding--06b35";
export var sectionTitle = "minutes_depot-module--section-title--012c8";
export var seeMore = "minutes_depot-module--seeMore--b2db3";
export var servicesBlock = "minutes_depot-module--servicesBlock--1e8d5";
export var termsLine = "minutes_depot-module--termsLine--f425e";
export var textMargin = "minutes_depot-module--textMargin--9389e";
export var textOutline = "minutes_depot-module--textOutline--524b1";
export var titleLine = "minutes_depot-module--titleLine--d40af";
export var titleLineAlign = "minutes_depot-module--titleLineAlign--88fdb";
export var titleTextMinutesDepot = "minutes_depot-module--titleTextMinutesDepot--f841f";
export var transition = "minutes_depot-module--transition--a6784";
export var transitionBackground = "minutes_depot-module--transition-background--795e9";
export var transitionReverse = "minutes_depot-module--transition-reverse--b098c";
export var underTitleTextMinutesDepot = "minutes_depot-module--underTitleTextMinutesDepot--93bff";
export var upTo2cols = "minutes_depot-module--upTo2cols--12111";
export var upTo2colsImgTxt = "minutes_depot-module--upTo2colsImgTxt--af823";
export var uppercase = "minutes_depot-module--uppercase--94286";
export var userAvatarMinutesDepot = "minutes_depot-module--userAvatarMinutesDepot--a6f83";
export var v2022 = "minutes_depot-module--v2022--29793";
export var whatIsMinutesDepot = "minutes_depot-module--whatIsMinutesDepot--bd899";
export var whiteButton = "minutes_depot-module--whiteButton--aae21";